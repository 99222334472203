<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        title="Historique des affiliations"
        route_name="gestion_de_la_structure__dashboard"
      />
    </v-row>
    <div v-if="!mounted" class="form-user-details__loading-container">
      <Loading :loading="true" />
    </div>
    <div v-if="isStructureMA" class="d-flex justify-content-end my-2">
      <Button
        :color="'#082B6E'"
        class="btn-validation mr-2"
        rounded
        height="48"
        type="primary"
        :class="{ disabled: !canReaffiliate }"
        @click="canReaffiliate ? (isReaffiliationMAOpen = true) : null"
        :disabled="isReaffiliationDisabled"
      >
        Ré-affilier la structure
      </Button>
    </div>
    <v-row>
      <v-col
        class="px-6"
        v-if="
          affiliationStructure &&
          affiliationStructure.STR_Affiliations &&
          affiliationStructure.STR_Affiliations.length > 0
        "
      >
        <HistoriqueLicence
          :context="{
            NomStructure: getStructureName,
            CodeFederal: getStructureCode,
            affiliations: getAffiliation,
            // licenceNumber: licenceNumber,
          }"
          :withTitle="false"
          :structure-fonction="fonctionUser"
        />
      </v-col>
      <v-col class="px-6" v-else>
        <p>Votre historique d'affiliation est vide</p>
      </v-col>
    </v-row>
    <ReaffiliationMA
      :is-open="isReaffiliationMAOpen"
      @close="isReaffiliationMAOpen = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import HistoriqueLicence from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/Historique_List.vue";
import Loading from "@/components/LoadingIndicator.vue";
import ReaffiliationMA from "./ReaffiliationMA.vue";
import Button from "@/components/utils/button.vue";
import { arrayRange } from "@/plugins/utils";

export default {
  components: {
    SubBar,
    HistoriqueLicence,
    Loading,
    ReaffiliationMA,
    Button,
  },

  data: () => ({
    affiliationStructure: null,
    licence: 0,
    fonctionUser: null,
    isReaffiliationMAOpen: false,
    mounted: false,
    canReaffiliate: false,
  }),

  async created() {
    await this.setData();
  },
  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),
    ...mapGetters("saisons", ["currentSaisonId", "nextSaison"]),
    isStructureMA() {
      // structure type MA
      return this.currentStructure.STR_Structure.ID_TypeStructure === 6;
    },
    getStructureName() {
      return this.affiliationStructure.NomStructure;
    },
    getAffiliation() {
      return this.affiliationStructure.STR_Affiliations;
    },
    getStructureCode() {
      return this.affiliationStructure.ID_CodeFederal;
    },
    isReaffiliationDisabled() {
      // Désactive les MA à partir du 1er décembre 2024 (voir https://ffme.atlassian.net/browse/MYFFME-136)
      return this.isStructureMA && new Date() > new Date("2024-12-01");
    }
  },

  methods: {
    ...mapActions("structure", [
      "getStructureAffiliations",
      "getStructureFonction",
      "getStructureAffiliation",
    ]),

    async setData() {
      this.affiliationStructure = await this.getStructureAffiliations({
        ID_Structure: this.currentStructureId,
        seasons: arrayRange(2005, this.nextSaison.ID_EtapeSaison >= 3 ? this.nextSaison.id : this.currentSaisonId, 1),
      });
      this.fonctionUser = await this.getStructureFonction({
        ID_Structure: this.currentStructureId,
        ID_Fonction: [1, 3, 5],
      });
      this.getAffiliations();
      this.mounted = true;
      return;
    },
    async getAffiliations() {
      const affiliationCurrentSeason = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.currentSaisonId,
      });

      const affiliationNextSeason = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.nextSaison.id,
      });

      this.canReaffiliate =
        affiliationCurrentSeason?.ID_EtapeAffiliation !== 3 ||
        (!this.isStructureMA() && affiliationNextSeason?.ID_EtapeAffiliation !== 3);
    }
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.3 !important;
}
</style>
