<template>
  <v-card height="100%" class="p-3 d-flex flex-column">
    <v-card-text class="text-center pb-0">
      <p class="subtitle-2 font-weight-bold text--primary">
        {{ context.title }}
      </p>

      <InputSelect
        v-if="!readOnly"
        placeholder="Nom de l'adresse"
        v-model="currentSelected"
        :items="structureAdresses"
        item_value="id"
        item_text="NomAdresse"
        :noLine="true"
        @change="changeSelectAdress"
        :disabled="disabled"
        :rules="'required'"
      >
      </InputSelect>

      <div class="caption mt-5" v-if="adresse">
        <p>{{ adresse.Adresse1 }}</p>
        <p>{{ adresse.Adresse2 }}</p>
        <p>{{ adresse.CodePostal }} {{ adresse.Ville }}</p>
      </div>
      <div class="caption mt-5" v-else>
        <p>Aucune adresse sélectionnée</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import InputSelect from "@/components/utils/selectWithInput.vue";
import { mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  data: () => ({
    adresses: [],
    currentSelected: "",
    mounted: false,
  }),
  components: { InputSelect },

  async created() {
    if (this.adresse) {
      this.currentSelected = this.structureAdresses.find(
        (adr) => adr.id == this.adresse.id
      );
    }
  },

  methods: {
    ...mapActions("structure", ["updateStructureAdresseAssignement"]),

    async changeSelectAdress(item) {
      let adresse = this.structureAdresses.find((adr) => adr.id == item.id);
      let payload = {
        type: this.context.type,
        adresse: adresse,
        ID_Structure: this.context.ID_Structure,
      };
      try {
        await this.updateStructureAdresseAssignement(payload);
        this.currentSelected = adresse;
        success_notification("Adresse assignée");
        this.$emit("onCardUpdate");
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },
  },
  watch: {
    adresse: function(newval) {
      this.currentSelected = newval;
    },
  },

  props: {
    context: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    structureAdresses: {
      type: Array,
    },

    adresse: {
      type: Object,
    },
  },
};
</script>
