<template>
  <div id="print-rc" ref="print-rc" v-if="context">
    <!-- page 1/4 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 0px;
        width: 595px;
        height: 841px;
        border-style: outset;
        overflow: hidden;
      "
    >
      <div style="position: absolute; left: 0px; top: 0px">
        <img
          src="~@/assets/attestattion/attestation_rc_logo.jpg"
          width="595"
          height="841"
        />
      </div>
      <div style="position: absolute; left: 250px; top: 8.76px" class="cls_002">
        <span class="cls_002"
          >Attestation responsabilité civile - Saison
          {{ context && context.item && context.item.ID_Saison }} - Contrat
          N°{{insuranceContractNumber}}</span
        >
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 48.66px"
        class="cls_003"
      >
        <span class="cls_003">17 Boulevard de la Gare - 31500 TOULOUSE</span>
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 70.26px"
        class="cls_003"
      >
        <span class="cls_003"
          >Tél. : 05.61.52.88.60 - Fax : 05.61.32.11.77</span
        >
      </div>
      <div
        style="position: absolute; left: 39.85px; top: 71.5px"
        class="cls_004"
      >
        <span class="cls_004">Cabinet</span>
      </div>
      <div
        style="position: absolute; left: 39.85px; top: 86.08px"
        class="cls_004"
      >
        <span class="cls_004">GOMIS - GARRIGUES</span>
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 91.86px"
        class="cls_003"
      >
        <span class="cls_003">E-mail : </span>
        <A HREF="mailto:5r09151@agents.allianz.fr">5r09151@agents.allianz.fr</A>
        <span class="cls_003"> - Site : </span>
        <A HREF="www.cabinet-gomis-garrigues.fr"
          >www.cabinet-gomis-garrigues.fr</A
        >
      </div>
      <div
        style="position: absolute; left: 39.85px; top: 111.84px"
        class="cls_006"
      >
        <span class="cls_006">Agents Généraux Allianz</span>
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 113.46px"
        class="cls_003"
      >
        <span class="cls_003">N°ORIAS : 07/020 818 - 08/045 968 - </span
        ><A HREF="http://www.orias.fr/">www.orias.fr</A>
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 124.26px"
        class="cls_003"
      >
        <span class="cls_003"
          >ACPR Autorité Contrôle Prudentiel et Résolution</span
        >
      </div>
      <div
        style="position: absolute; left: 194.36px; top: 135.06px"
        class="cls_003"
      >
        <span class="cls_003">4 Place de Budapest 75436 PARIS</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 169.67px"
        class="cls_006"
      >
        <span class="cls_006">STRUCTURE : {{ context.NomStructure }}</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 180.47px"
        class="cls_006"
      >
        <span class="cls_006"
          >N° AFFILIATION FFME : {{ context.CodeFederal }}</span
        >
      </div>
      <div
        style="position: absolute; left: 94.14px; top: 223.3px"
        class="cls_014"
      >
        <span class="cls_014"
          >ATTESTATION D'ASSURANCE RESPONSABILITÉ CIVILE</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 255.14px"
        class="cls_003"
      >
        <span class="cls_003"
          >Nous soussignés Cabinet GOMIS - GARRIGUES, Agents Généraux
          d'Assurance, mandataires de la société</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 265.94px"
        class="cls_003"
      >
        <span class="cls_003"
          >ALLIANZ dont le siège social est situé 1, cours Michelet - CS 30051 -
          92076 PARIS la Défense Cedex, certifient</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 276.74px"
        class="cls_003"
      >
        <span class="cls_003">garantir par contrat n°{{insuranceContractNumber}} la </span
        ><span class="cls_008">RESPONSABILITÉ CIVILE</span
        ><span class="cls_003"> de :</span>
      </div>
      <div
        style="position: absolute; left: 130.31px; top: 298.42px"
        class="cls_006"
      >
        <span class="cls_006"
          >LA FÉDÉRATION FRANÇAISE DE LA MONTAGNE ET DE L'ESCALADE</span
        >
      </div>
      <div
        style="position: absolute; left: 238.98px; top: 309.22px"
        class="cls_009"
      >
        <span class="cls_009">8/10 QUAI DE LA MARNE</span>
      </div>
      <div
        style="position: absolute; left: 267.32px; top: 320.02px"
        class="cls_009"
      >
        <span class="cls_009">75019 PARIS</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 345.91px"
        class="cls_003"
      >
        <span class="cls_003"
          >Conformément à l'Article L.112-3 du Code des Assurances, la présente
          Attestation vaut simple présomption de</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 356.71px"
        class="cls_003"
      >
        <span class="cls_003">garantie.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 378.31px"
        class="cls_015"
      >
        <span class="cls_015">LES PERSONNES ASSURÉES :</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 399.91px"
        class="cls_008"
      >
        <span class="cls_008"
          >Les personnes assurées pour les garanties Responsabilité Civile,
          Défense Pénale et Recours :</span
        >
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 423.62px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 421.51px"
        class="cls_003"
      >
        <span class="cls_003">La Fédération, souscripteur du contrat,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 434.43px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 432.32px"
        class="cls_003"
      >
        <span class="cls_003">Les ligues,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 445.23px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 443.12px"
        class="cls_003"
      >
        <span class="cls_003">Les comités territoriaux,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 456.04px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 453.93px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les clubs et associations à but non lucratif affiliés,</span
        >
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 466.85px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 464.73px"
        class="cls_003"
      >
        <span class="cls_003">Les dirigeants statutaires,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 477.65px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 475.54px"
        class="cls_003"
      >
        <span class="cls_003">Les préposés, rémunérés ou non,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 488.46px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 486.35px"
        class="cls_003"
      >
        <span class="cls_003">Les encadrants bénévoles,</span>
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 499.26px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 497.15px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les membres des groupements titulaires d'une licence fédérale en
          cours de validité, ainsi que leurs parents ou</span
        >
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 507.95px"
        class="cls_003"
      >
        <span class="cls_003"
          >tuteurs en leur qualité de civilement responsables,</span
        >
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 520.87px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 518.76px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les personnes prêtant bénévolement leur concours à un assuré dans le
          cadre des activités garanties,</span
        >
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 531.67px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 529.56px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les participants (licenciés ou non) valablement engagés dans une
          manifestation sportive organisée par la</span
        >
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 540.36px"
        class="cls_003"
      >
        <span class="cls_003">Fédération Française de la Montagne et de l’Escalade.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 561.97px"
        class="cls_006"
      >
        <span class="cls_006"
          >Les personnes assurées pour les garanties Individuelle Accident
          :</span
        >
      </div>
      <div
        style="position: absolute; left: 53.6px; top: 585.68px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 583.57px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les titulaires des licences de la Fédération Française de la Montagne et de l’Escalade de l'année en cours, et </span
        ><span class="cls_008"
          >pour</span
        >
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 594.37px"
        class="cls_008"
      >
        <span class="cls_008"
          > les activités de ski les licenciés ayant souscrit l'option ski de piste, pour le VTT l'option VTT, pour la</span
        >
      </div>
      <div
        style="position: absolute; left: 62.37px; top: 605.17px"
        class="cls_008"
      >
        <span class="cls_008"
          > slackline/highline de plus de 60cm du sol l'option slackline/highline et pour le trail l'option trail.</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 626.77px"
        class="cls_006"
      >
        <span class="cls_006"
          >Particularités relatives à la qualité de certains Assurés :</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 648.37px"
        class="cls_003"
      >
        <span class="cls_003"
          >Les ressortissants étrangers domiciliés hors de France et titulaires
          d'une licence de la Fédération Française de la</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 659.17px"
        class="cls_003"
      >
        <span class="cls_003"
          >Montagne et de l’Escalade bénéficient des garanties (Responsabilité Civile, atteinte corporelle,
          assistance-rapatriement)</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 669.97px"
        class="cls_003"
      >
        <span class="cls_003"
          >en France métropolitaine et DROM-COM pour les activités statutaires de la Fédération qu'elles soient ou non pratiquées</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 680.77px"
        class="cls_003"
      >
        <span class="cls_003"
          >au sein de clubs ou associations affiliés, ligues ou territoriaux. En revanche, ils ne sont garantis hors de France</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 691.57px"
        class="cls_003"
      >
        <span class="cls_003"
          >métropolitaine et DROM-COM que si ces activités sont organisées par la Fédération Française de la Montagne et de</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 702.37px"
        class="cls_003"
      >
        <span class="cls_003"
          >l’Escalade (ligues, comités territoriaux, clubs).</span
        >
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 780.31px"
        class="cls_002"
      >
        <span class="cls_002">page 1/4</span>
      </div>
    </div>
    <!-- page 2/4 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 851px;
        width: 595px;
        height: 841px;
        border-style: outset;
        overflow: hidden;
      "
    >
      <div style="position: absolute; left: 0px; top: 0px">
        <img
          src="~@/assets/attestattion/table-amount.jpg"
          width="595"
          height="841"
        />
      </div>

      <div style="position: absolute; left: 250px; top: 8.76px" class="cls_002">
        <span class="cls_002"
          >Attestation responsabilité civile - Saison
          {{ context.item.ID_Saison }} - Contrat N°{{insuranceContractNumber}}</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 34.86px"
        class="cls_015"
      >
        <span class="cls_015">MONTANT DES GARANTIES :</span>
      </div>
      <div
        style="position: absolute; left: 40.6px; top: 60.16px"
        class="cls_011"
      >
        <span
          style="
            font-family: Arial, serif !important;
            font-size: 10.6px !important;
            color: rgb(0, 51, 102);
            font-weight: bold !important;
            font-style: normal !important;
            text-decoration: none !important;
          "
          >Nature des garanties de Responsabilité Civile</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 60.16px"
        class="cls_011"
      >
        <span class="cls_011">Montants des garanties</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 60.16px"
        class="cls_011"
      >
        <span class="cls_011">Franchises par</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 71.5px"
        class="cls_011"
      >
        <span class="cls_011">sinistre</span>
      </div>
      <div
        style="position: absolute; left: 40.6px; top: 87.4px"
        class="cls_003"
      >
        <span class="cls_003"
          >Tous dommages corporels, matériels et immatériels</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 87.4px"
        class="cls_003"
      >
        <span class="cls_003">12 000 000</span>
      </div>
      <div
        style="position: absolute; left: 373.94px; top: 87.4px"
        class="cls_003"
      >
        <span class="cls_003">€ par sinistre</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 87.4px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 40.6px; top: 98.2px"
        class="cls_003"
      >
        <span class="cls_003">confondus</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 98.2px"
        class="cls_003"
      >
        <span class="cls_003">et par année d'assurance</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 129.88px"
        class="cls_003"
      >
        <span class="cls_003"
          >* Dommages ne résultant pas d'une atteinte à</span
        >
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 140.68px"
        class="cls_003"
      >
        <span class="cls_003">l'environnement dont :</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 162.28px"
        class="cls_003"
      >
        <span class="cls_003"
          >Dommages corporels (hors faute inexcusable de l'employeur)</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 163px"
        class="cls_003"
      >
        <span class="cls_003">inclus</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 163px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 173.08px"
        class="cls_003"
      >
        <span class="cls_003">RC pour défaut d'information</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 173.8px"
        class="cls_003"
      >
        <span class="cls_003">inclus</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 173.8px"
        class="cls_003"
      >
        <span class="cls_003">10% du montant de</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 184.6px"
        class="cls_003"
      >
        <span class="cls_003">l'indemnité avec un</span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 195.4px"
        class="cls_003"
      >
        <span class="cls_003">maximum de 1 525€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 216.28px"
        class="cls_003"
      >
        <span class="cls_003"
          >RC médicale des médecins et personnels médicaux salariés</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 217px"
        class="cls_003"
      >
        <span class="cls_003">8 000 000</span>
      </div>
      <div
        style="position: absolute; left: 368.38px; top: 217px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 217px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 227.08px"
        class="cls_003"
      >
        <span class="cls_003">ou bénévoles</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 237.88px"
        class="cls_003"
      >
        <span class="cls_003"
          >Dommages corporels aux préposés en cas de faute</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 238.6px"
        class="cls_003"
      >
        <span class="cls_003">3 000 000</span>
      </div>
      <div
        style="position: absolute; left: 368.38px; top: 238.6px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 238.6px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 248.68px"
        class="cls_003"
      >
        <span class="cls_003">inexcusable</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 270.28px"
        class="cls_003"
      >
        <span class="cls_003"
          >Dommages matériels et immatériels consécutifs</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 271px"
        class="cls_003"
      >
        <span class="cls_003">2 500 000</span>
      </div>
      <div
        style="position: absolute; left: 368.38px; top: 271px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 271px"
        class="cls_003"
      >
        <span class="cls_003">150</span>
      </div>
      <div
        style="position: absolute; left: 482.07px; top: 271px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 281.08px"
        class="cls_003"
      >
        <span class="cls_003">RC Vol</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 281.8px"
        class="cls_003"
      >
        <span class="cls_003">150 000</span>
      </div>
      <div
        style="position: absolute; left: 359.47px; top: 281.8px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 281.8px"
        class="cls_003"
      >
        <span class="cls_003">150</span>
      </div>
      <div
        style="position: absolute; left: 482.07px; top: 281.8px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 291.88px"
        class="cls_003"
      >
        <span class="cls_003">RC dépositaire</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 292.6px"
        class="cls_003"
      >
        <span class="cls_003">76 225</span>
      </div>
      <div
        style="position: absolute; left: 354.47px; top: 292.6px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 292.6px"
        class="cls_003"
      >
        <span class="cls_003">150</span>
      </div>
      <div
        style="position: absolute; left: 482.07px; top: 292.6px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 302.68px"
        class="cls_003"
      >
        <span class="cls_003">Dommages immatériels non consécutifs</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 303.4px"
        class="cls_003"
      >
        <span class="cls_003">1 000 000</span>
      </div>
      <div
        style="position: absolute; left: 368.38px; top: 303.4px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 303.4px"
        class="cls_003"
      >
        <span class="cls_003">150</span>
      </div>
      <div
        style="position: absolute; left: 482.07px; top: 303.4px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 313.48px"
        class="cls_012"
      >
        <span class="cls_012">......</span
        ><span class="cls_003">- Dont protection de l'image</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 314.2px"
        class="cls_003"
      >
        <span class="cls_003">50 000€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 314.2px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 335.08px"
        class="cls_003"
      >
        <span class="cls_003"
          >* Tous dommages corporels, matériels et immatériels</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 335.8px"
        class="cls_003"
      >
        <span class="cls_003">750 000</span>
      </div>
      <div
        style="position: absolute; left: 360.04px; top: 335.8px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 345.88px"
        class="cls_003"
      >
        <span class="cls_003"
          >consécutifs confondus résultant d'une atteinte à</span
        >
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 356.68px"
        class="cls_003"
      >
        <span class="cls_003">l'environnement accidentelle</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 368.2px"
        class="cls_003"
      >
        <span class="cls_003">700 000</span>
      </div>
      <div
        style="position: absolute; left: 360.04px; top: 368.2px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 462.61px; top: 368.2px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 378.28px"
        class="cls_003"
      >
        <span class="cls_003"
          >* Responsabilité des dirigeants et mandataires sociaux</span
        >
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 389.08px"
        class="cls_003"
      >
        <span class="cls_003">dont :</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 389.8px"
        class="cls_003"
      >
        <span class="cls_003">70 000</span>
      </div>
      <div
        style="position: absolute; left: 354.47px; top: 389.8px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 399.88px"
        class="cls_003"
      >
        <span class="cls_003">- Frais de comparution</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 400.6px"
        class="cls_003"
      >
        <span class="cls_003">100 000</span>
      </div>
      <div
        style="position: absolute; left: 360.04px; top: 400.6px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 410.68px"
        class="cls_003"
      >
        <span class="cls_003"
          >- Frais de prévention des personnes morales assurées en</span
        >
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 421.48px"
        class="cls_003"
      >
        <span class="cls_003">difficulté</span>
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 422.2px"
        class="cls_003"
      >
        <span class="cls_003">70 000</span>
      </div>
      <div
        style="position: absolute; left: 354.47px; top: 422.2px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 432.28px"
        class="cls_003"
      >
        <span class="cls_003"
          >- Frais de reconstitution de l'image des dirigeants</span
        >
      </div>
      <div
        style="position: absolute; left: 321.11px; top: 433px"
        class="cls_003"
      >
        <span class="cls_003">70 000</span>
      </div>
      <div
        style="position: absolute; left: 354.47px; top: 433px"
        class="cls_003"
      >
        <span class="cls_003">€ <span style="font-size: 8px;">par année d'assurance</span></span>
      </div>
      <div
        style="position: absolute; left: 46.69px; top: 443.08px"
        class="cls_003"
      >
        <span class="cls_003">- Frais d'assistance psychologique</span>
      </div>
      <div
        style="position: absolute; left: 40.6px; top: 503.02px"
        class="cls_011"
      >
        <span class="cls_011">DÉFENSE PENALE ET RECOURS</span>
      </div>
      <div
        style="position: absolute; left: 255.41px; top: 503.02px"
        class="cls_011"
      >
        <span class="cls_011">MONTANTS DE</span>
      </div>
      <div
        style="position: absolute; left: 416.51px; top: 503.02px"
        class="cls_011"
      >
        <span class="cls_011">SEUIL SPECIAL</span>
      </div>
      <div
        style="position: absolute; left: 255.41px; top: 514.36px"
        class="cls_011"
      >
        <span class="cls_011">GARANTIE</span>
      </div>
      <div
        style="position: absolute; left: 416.51px; top: 514.36px"
        class="cls_011"
      >
        <span class="cls_011">D'INTERVENTION</span>
      </div>
      <div
        style="position: absolute; left: 43.6px; top: 533.26px"
        class="cls_003"
      >
        <span class="cls_003">· Défense devant toute juridiction</span>
      </div>
      <div
        style="position: absolute; left: 258.41px; top: 533.26px"
        class="cls_003"
      >
        <span class="cls_003">Frais à la charge de l'assureur</span>
      </div>
      <div
        style="position: absolute; left: 419.51px; top: 533.26px"
        class="cls_003"
      >
        <span class="cls_003">Néant</span>
      </div>
      <div
        style="position: absolute; left: 43.6px; top: 565.66px"
        class="cls_003"
      >
        <span class="cls_003">· Recours</span>
      </div>
      <div
        style="position: absolute; left: 258.41px; top: 565.66px"
        class="cls_003"
      >
        <span class="cls_003">50 000</span>
      </div>
      <div
        style="position: absolute; left: 291.77px; top: 565.66px"
        class="cls_003"
      >
        <span class="cls_003">€ par sinistre</span>
      </div>
      <div
        style="position: absolute; left: 419.51px; top: 565.66px"
        class="cls_003"
      >
        <span class="cls_003">Nous n'intervenons pas pour</span>
      </div>
      <div
        style="position: absolute; left: 419.51px; top: 576.46px"
        class="cls_003"
      >
        <span class="cls_003">les réclamations inférieures</span>
      </div>
      <div
        style="position: absolute; left: 419.51px; top: 587.26px"
        class="cls_003"
      >
        <span class="cls_003">ou égales à 300</span>
      </div>
      <div
        style="position: absolute; left: 493.46px; top: 587.26px"
        class="cls_003"
      >
        <span class="cls_003">€</span>
      </div>
      <div
        style="position: absolute; left: 43.6px; top: 633.17px"
        class="cls_003"
      >
        <span class="cls_003"
          >Protection juridique pour les ligues et les comités</span
        >
      </div>
      <div
        style="position: absolute; left: 324.23px; top: 633.17px"
        class="cls_003"
      >
        <span class="cls_003">16 000</span>
      </div>
      <div
        style="position: absolute; left: 357.59px; top: 633.17px"
        class="cls_003"
      >
        <span class="cls_003">€ TTC par litige</span>
      </div>
      <div
        style="position: absolute; left: 465.22px; top: 636.92px"
        class="cls_003"
      >
        <span class="cls_003">Seuil d'intervention :</span>
      </div>
      <div
        style="position: absolute; left: 43.6px; top: 643.97px"
        class="cls_003"
      >
        <span class="cls_003">territoriaux.</span>
      </div>
      <div
        style="position: absolute; left: 319.78px; top: 643.97px"
        class="cls_003"
      >
        <span class="cls_003">dont expertise judiciaire :</span>
      </div>
      <div
        style="position: absolute; left: 466.18px; top: 647.72px"
        class="cls_003"
      >
        <span class="cls_003">650</span>
      </div>
      <div
        style="position: absolute; left: 485.64px; top: 647.72px"
        class="cls_003"
      >
        <span class="cls_003">€ TTC par litige</span>
      </div>
      <div
        style="position: absolute; left: 327.01px; top: 654.77px"
        class="cls_003"
      >
        <span class="cls_003">4 800</span>
      </div>
      <div
        style="position: absolute; left: 354.81px; top: 654.77px"
        class="cls_003"
      >
        <span class="cls_003">€ TTC par litige</span>
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 780.31px"
        class="cls_002"
      >
        <span class="cls_002">page 2/4</span>
      </div>
    </div>
    <!-- page 3/4 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 1702px;
        width: 595px;
        height: 841px;
        border-style: outset;
        overflow: hidden;
      "
    >
      <!-- <div style="position:absolute;left:0px;top:0px">
        <img
          src="e878c684-aaa6-11ec-a980-0cc47a792c0a_id_e878c684-aaa6-11ec-a980-0cc47a792c0a_files/background3.jpg"
          width="595"
          height="841"
        />
      </div> -->

      <div style="position: absolute; left: 250px; top: 8.76px" class="cls_002">
        <span class="cls_002"
          >Attestation responsabilité civile - Saison
          {{ context.item.ID_Saison }} - Contrat N°{{insuranceContractNumber}}</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 34.86px"
        class="cls_015"
      >
        <span class="cls_015">LES ACTIVITÉS ASSURÉES :</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 56.46px"
        class="cls_003"
      >
        <span class="cls_003"
          >a) La pratique autonome ou encadrée, de loisir ou compétitive, en
          France ou à l'étranger des activités statutaires :</span
        >
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 72.76px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 70.65px"
        class="cls_003"
      >
        <span class="cls_003">Alpinisme, cascade sur glace, dry-tooling,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 83.56px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 81.45px"
        class="cls_003"
      >
        <span class="cls_003">Canyonisme,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 94.37px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 92.26px"
        class="cls_003"
      >
        <span class="cls_003">Escalade et para-escalade,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 105.17px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 103.06px"
        class="cls_003"
      >
        <span class="cls_003">Expéditions lointaines,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 115.98px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 113.87px"
        class="cls_003"
      >
        <span class="cls_003">Randonnées de montagne, trekkings,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 126.78px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 124.67px"
        class="cls_003"
      >
        <span class="cls_003">Raquette à neige,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 137.59px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 135.48px"
        class="cls_003"
      >
        <span class="cls_003"
          >Ski-alpinisme (ski de randonnée), surf de montagne ( surf de
          randonnée) en et hors domaine skiable,</span
        >
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 148.4px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 146.29px"
        class="cls_003"
      >
        <span class="cls_003">Via ferrata,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 159.2px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 157.09px"
        class="cls_003"
      >
        <span class="cls_003">Escalad'arbre,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 170.01px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 167.9px"
        class="cls_003"
      >
        <span class="cls_003">Slackline (hauteur : 0,60 m du sol max).</span>
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 200.3px"
        class="cls_008"
      >
        <span class="cls_008">Sous réserve pour les personnes physiques</span
        ><span class="cls_003">
          d'être titulaires de la licence Fédération Française de la Montagne et</span
        >
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 211.1px"
        class="cls_003"
      >
        <span class="cls_003">de l’Escalade en faisant mention et du paiement d'un complément de cotisation,</span>
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 221.9px"
        class="cls_016"
      >
        <span class="cls_016">1°) Les activités de ski de piste :</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 238.11px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 236px"
        class="cls_003"
      >
        <span class="cls_003">Ski alpin,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 248.92px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 246.81px"
        class="cls_003"
      >
        <span class="cls_003">Surf des neiges,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 259.72px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 257.61px"
        class="cls_003"
      >
        <span class="cls_003">Monoski sur pistes,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 270.53px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 268.42px"
        class="cls_003"
      >
        <span class="cls_003">Ski de fond,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 281.34px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 279.22px"
        class="cls_003"
      >
        <span class="cls_003">Ski nordique,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 292.14px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 290.03px"
        class="cls_003"
      >
        <span class="cls_003">Télémark,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 302.95px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 300.84px"
        class="cls_003"
      >
        <span class="cls_003">Ski voile,</span>
      </div>
      <div
        style="position: absolute; left: 68.69px; top: 313.75px"
        class="cls_010"
      >
        <span class="cls_010">●</span>
      </div>
      <div
        style="position: absolute; left: 77.45px; top: 311.64px"
        class="cls_003"
      >
        <span class="cls_003">Différentes pratiques du ski.</span>
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 344.05px"
        class="cls_016"
      >
        <span class="cls_016">2°) Le VTT :</span
        ><span class="cls_003">
          pratiqué en France, Principautés d’Andorre et de Monaco, Suisse, Royaume-Uni et pays de l’Union</span
        >
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 354.85px"
        class="cls_003"
      >
        <span class="cls_003"> Européenne (activité non garantie dans le reste du monde).</span>
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 365.65px"
        class="cls_016"
      >
        <span class="cls_016">3°) La slackline / highline :</span
        ><span class="cls_003">
          pour l'activité au-delà de 60 cm du sol, pratiquées en France
          , Principautés d’Andorre</span
        >
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 376.45px"
        class="cls_003"
      >
        <span class="cls_003"
          > et de Monaco, Suisse, Royaume-Uni et pays de l’Union Européenne (activité non garantie dans le
          reste du monde).</span
        >
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 387.25px"
        class="cls_016"
      >
        <span class="cls_016">4°) Le trail :</span
        ><span class="cls_003">
          pratiqué en France, Principautés d’Andorre et de Monaco, Suisse, Royaume-Uni et pays de l’Union</span
        >
      </div>
      <div
        style="position: absolute; left: 51.94px; top: 398.05px"
        class="cls_003"
      >
        <span class="cls_003">Européenne (activité non garantie dans le reste du monde).</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 419.74px"
        class="cls_003"
      >
        <span class="cls_003"
          >b) L'organisation par la Fédération Française de la Montagne et de l’Escalade et/ou ses organes déconcentrés et/ou
          les</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 430.54px"
        class="cls_003"
      >
        <span class="cls_003"
          >clubs, associations affiliées de stages y compris l'internat, rencontres, compétitions en France </span
        ><span class="cls_008"
          >ainsi que toute autre</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 441.34px"
        class="cls_008"
      >
        <span class="cls_008">activité programmée par les responsables</span
        ><span class="cls_003">
          encadrant ces stages, rencontres, compétitions et </span
        ><span class="cls_008">manifestations</span
        ><span class="cls_003">.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 452.14px"
        class="cls_003"
      >
        <span class="cls_003"
          >c) La participation et l'organisation de congrès, réunions,
          conférences, nationaux et internationaux, régionaux,</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 462.94px"
        class="cls_003"
      >
        <span class="cls_003">départementaux et locaux.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 473.74px"
        class="cls_003"
      >
        <span class="cls_003"
          >d) Les déplacements et voyages nécessaires à la pratique des
          activités assurées. Sous réserve pour les voyages</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 484.54px"
        class="cls_003"
      >
        <span class="cls_003"
          >hors de l'Union Européenne, Principautés d’Andorre et de Monaco, Suisse, Royaume-Uni, d'un agrément
          préalablement</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 495.34px"
        class="cls_003"
      >
        <span class="cls_003"
          >délivré par la Fédération et du paiement d'une</span
        >
      </div>
      <div
        style="position: absolute; left: 246.68px; top: 495.34px"
        class="cls_003"
      >
        <span class="cls_003">cotisation supplémentaire de 50€ TTC par personne et par saison sportive.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 506.14px"
        class="cls_003"
      >
        <span class="cls_003"
          >e) l'organisation des congrès nationaux, régionaux, départementaux
          ainsi que les défilés et toutes manifestations</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 516.94px"
        class="cls_003"
      >
        <span class="cls_003"
          >organisées à l'occasion des manifestations nationales, régionales et
          départementales de la Fédération Française de la</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 527.74px"
        class="cls_003"
      >
        <span class="cls_003"
          >Montagne et de l’Escalade.</span
        >
      </div>

      <div
        style="position: absolute; left: 36.85px; top: 538.54px"
        class="cls_003"
      >
        <span class="cls_003"
          >f) L'organisation d'activités extra sportives telles que fêtes, bals,
          repas organisées par les personnes morales</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 549.34px"
        class="cls_003"
      >
        <span class="cls_003">assurées.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 560.14px"
        class="cls_003"
      >
        <span class="cls_003"
          >g) L'organisation de formation professionnelle d'escalade sportive (
          D.E d'escalade) dispensée par la Fédération et</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 570.94px"
        class="cls_003"
      >
        <span class="cls_003">ses organes déconcentrés.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 581.74px"
        class="cls_003"
      >
        <span class="cls_003"
          >h) La gestion par la Fédération Française de la Montagne et de l’Escalade de salles d’Escalade.</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 635.74px"
        class="cls_015"
      >
        <span class="cls_015">OBJET DE LA GARANTIE :</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 657.34px"
        class="cls_003"
      >
        <span class="cls_003"
          >Ce contrat a pour objet de garantir l'Assuré, dans la limite des
          sommes fixées ci-dessus, contre les conséquences</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 668.14px"
        class="cls_003"
      >
        <span class="cls_003"
          >pécuniaires de la Responsabilité Civile pouvant lui incomber en
          raison des dommages corporels, matériels et</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 678.94px"
        class="cls_003"
      >
        <span class="cls_003"
          >immatériels causés à autrui dans l'exercice des activités
          garanties.</span
        >
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 780.31px"
        class="cls_002"
      >
        <span class="cls_002">page 3/4</span>
      </div>
    </div>
    <!-- page 4/4 -->
    <div
      class="pdf-to-print"
      style="
        position: absolute;
        left: 50%;
        margin-left: -297px;
        top: 2553px;
        width: 595px;
        height: 841px;
        border-style: outset;
        overflow: hidden;
      "
    >
      <div style="position: absolute; left: 0px; top: 0px">
        <img src="~@/assets/attestattion/tag.jpg" width="595" height="841" />
      </div>

      <div style="position: absolute; left: 250px; top: 8.76px" class="cls_002">
        <span class="cls_002"
          >Attestation responsabilité civile - Saison
          {{ context.item.ID_Saison }} - Contrat N°{{insuranceContractNumber}}</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 34.86px"
        class="cls_015"
      >
        <span class="cls_015"
          >GARANTIE OCCUPATION TEMPORAIRE D'UN BATIMENT :</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 56.46px"
        class="cls_008"
      >
        <span class="cls_008"
          >L'assureur garantit notamment la Responsabilité Civile pouvant
          incomber aux organisations assurées en leur</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 67.26px"
        class="cls_008"
      >
        <span class="cls_008"
          >qualité d'occupant temporaire d'un bâtiment inférieur à 15 jours
          consécutifs avec ou sans contrat de location</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 78.06px"
        class="cls_008"
      >
        <span class="cls_008"
          >ou dans le cadre d'une convention de mise à disposition par créneaux
          horaires vis à vis du propriétaire, des</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 88.86px"
        class="cls_008"
      >
        <span class="cls_008">voisins et des tiers.</span>
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 110.46px"
        class="cls_003"
      >
        <span class="cls_003"
          >La présente attestation, dont la validité est fixée du 01/09/{{
            this.saison - 1
          }}
          au 31/08/{{ this.saison }} sous réserve du renouvellement de</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 121.26px"
        class="cls_003"
      >
        <span class="cls_003"
          >l'affiliation à la FFME ne peut engager ALLIANZ IART en dehors des
          clauses et conditions du contrat auquel elle se</span
        >
      </div>
      <div
        style="position: absolute; left: 36.85px; top: 132.06px"
        class="cls_003"
      >
        <span class="cls_003">réfère.</span>
      </div>
      <div
        style="position: absolute; left: 354.96px; top: 166.71px"
        class="cls_006"
      >
        <span class="cls_006">P/La Compagnie</span>
      </div>
      <div
        style="position: absolute; left: 39.1px; top: 177.51px"
        class="cls_003"
      >
        <span class="cls_003"
          >Fait à Paris, le {{ $moment().format("DD/MM/YYYY") }}.</span
        >
      </div>
      <div
        style="position: absolute; left: 323.01px; top: 177.51px"
        class="cls_006"
      >
        <span class="cls_006">Cabinet GOMIS - GARRIGUES</span>
      </div>
      <div
        style="position: absolute; left: 299.15px; top: 758.31px"
        class="cls_002"
      >
        <span class="cls_002">page 4/4</span>
      </div>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    context: null,
    saison: 0,
    structureName: "",
    structureCode: "",
    tableau: [],
    insuranceContractNumber: null,
  }),
  methods: {
    ...mapActions("basics", ["getTemplateByName"]),

    async printRC(context) {
      this.context = context;
      this.saison = context.item.ID_Saison;
      this.insuranceContractNumber = this.getSaison(context.item.ID_Saison)?.config?.insuranceContractNumber || 60337812;
      this.structureName = context.NomStructure;
      this.structureCode = context.CodeFederal;
      this.$nextTick(async () => {
        this.$refs["print-rc"].style.display = "block";

        let pdf = new jsPDF({
          orientation: "portrait",
        });
        let sources = document.getElementsByClassName("pdf-to-print");
        let promisePdf = [];
        sources.forEach((source) => {
          return promisePdf.push(
            html2canvas(source, {
              scale: 2,
              letterRendering: 1,
              logging: true,
              useCORS: true,
            })
          );
        });
        await Promise.all(promisePdf).then((canvases) => {
          canvases.forEach((canvas, index) => {
            const imgData = canvas.toDataURL("image/jpeg");
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            if (index !== 0) pdf.addPage();
            pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
          });
          pdf.save("Attestation RC " + this.saison);
        });
        this.$refs["print-rc"].style.display = "none";
      });
    },
  },
  computed: {
    ...mapGetters("saisons", ["currentSaisonId", "getSaison"]),
  },
};
</script>
<style lang="scss">
#print-rc {
  width: 1000px;
  position: absolute;
  z-index: -3;
  position: absolute;
  display: none;

  .cls_002 {
    font-family: Arial, serif !important;
    font-size: 10.1px !important;
    color: rgb(193, 193, 193) !important;
    font-weight: normal !important;
    font-style: normal !important;
    text-decoration: none;
  }

  .cls_003 {
    font-family: Arial, serif !important;
    font-size: 10.1px !important;
    color: rgb(0, 0, 0);
    font-weight: normal !important;
    font-style: normal !important;
    text-decoration: none;
  }

  .cls_004 {
    font-family: Arial, serif;
    font-size: 13.6px;
    color: rgb(0, 51, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }

  span.cls_013 {
    font-family: Arial, serif !important;
    font-size: 10.1px !important;
    color: rgb(19, 19, 249);
    font-weight: normal !important;
    font-style: normal !important;
    text-decoration: underline !important;
  }
  div.cls_013 {
    font-family: Arial, serif !important;
    font-size: 10.1px !important;
    color: rgb(19, 19, 249);
    font-weight: normal !important;
    font-style: normal !important;
    text-decoration: none;
  }
  .cls_006 {
    font-family: Arial, serif !important;
    font-size: 10.1px !important;
    color: rgb(0, 51, 102);
    font-weight: bold !important;
    font-style: normal !important;
    text-decoration: none;
  }

  span.cls_014 {
    font-family: Arial, serif !important;
    font-size: 15.1px !important;
    color: rgb(0, 51, 102) !important;
    font-weight: bold !important;
    font-style: normal;
    text-decoration: underline !important;
  }
  div.cls_014 {
    font-family: Arial, serif !important;
    font-size: 15.1px !important;
    color: rgb(0, 51, 102);
    font-weight: bold !important;
    font-style: normal;
    text-decoration: none !important;
  }
  .cls_008 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }

  .cls_009 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 51, 102);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }

  span.cls_015 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 51, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: underline;
  }
  div.cls_015 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 51, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }
  .cls_010 {
    font-family: Arial, serif;
    font-size: 7.5px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }

  .cls_011 {
    font-family: Arial, serif;
    font-size: 10.6px;
    color: rgb(0, 51, 102);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }

  .cls_012 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(255, 255, 255);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }

  span.cls_016 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: underline;
  }
  div.cls_016 {
    font-family: Arial, serif;
    font-size: 10.1px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }
}
</style>
