<template>
  <tr>
    <td>
      <div class="d-flex align-center justify-content-center text1 text2">
        <v-chip outlined color="#082B6E">
          {{ `${+item.ID_Saison - 1}-${item.ID_Saison}` }}
        </v-chip>
      </div>
    </td>
    <td>
      <div class="d-flex align-center justify-content-center">
        <span
          v-if="structurePresident !== ''"
          class="text1"
          v-text="structurePresident"
        />
        <v-chip v-else color="grey" text-color="white" v-text="'N.C'" />
      </div>
    </td>
    <td>
      <div class="d-flex align-center justify-content-center">
        <span
          v-if="structureSecretary !== ''"
          class="text1"
          v-text="structureSecretary"
        />
        <v-chip v-else color="grey" text-color="white" v-text="'N.C'" />
      </div>
    </td>
    <td>
      <div class="d-flex align-center justify-content-center">
        <span
          v-if="structureTresorary !== ''"
          class="text1"
          v-text="structureTresorary"
        />
        <v-chip v-else color="grey" text-color="white" v-text="'N.C'" />
      </div>
    </td>
    <td>
      <div class="d-flex align-center justify-content-center text1 text2">
        <v-chip
          :outlined="
            item.ID_Saison === currentSaisonId &&
            item.LIST_EtapeAffiliation.id === 3
          "
          :disabled="
            item.ID_Saison !== currentSaisonId ||
            (item.ID_Saison === currentSaisonId &&
              item.LIST_EtapeAffiliation.id !== 3)
          "
          :text-color="
            item.ID_Saison !== currentSaisonId ||
            (item.ID_Saison === currentSaisonId &&
              item.LIST_EtapeAffiliation.id !== 3)
              ? 'white'
              : '#082B6E'
          "
          color="#082B6E"
        >
          <v-icon size="18" class="mr-2">mdi-check-circle-outline</v-icon>
          <span v-text="getAffiliationStatusText(item)" />
          <!-- <span :v-text="item.LIST_EtapeAffiliation.id !=3 ? 'non-active':'active'" /> -->
        </v-chip>
      </div>
    </td>
    <v-tooltip
      bottom
      color="#90a1be"
      :disabled="!isMoreThanTwoYear || !isNotAffiliated"
      max-width="400"
    >
      <template v-slot:activator="{ on }">
        <td style="position: relative;" class="p-2" v-on="on">
          <v-expansion-panels
            :flat="test !== 0"
            class="panel-extension"
            :class="{ active: test === 0 }"
            v-model="test"
            style="height: 95%;"
            :disabled="isMoreThanTwoYear || isAnticipated || isNotAffiliated"
          >
            <v-expansion-panel class="test" active-class="active">
              <v-expansion-panel-header class="d-flex align-center">
                <span
                  class="text1 text2"
                  :class="{ disabled: isMoreThanTwoYear || isAnticipated || isNotAffiliated }"
                >
                  Télécharger les attestations</span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="content-panel">
                <div
                  v-for="(attestation, index) in attestationList"
                  :key="attestation.id"
                  class="d-flex align-center select-item p-2"
                >
                  <v-checkbox
                    v-model="boxItem[index]"
                    style="margin-top: 3px !important;"
                    @change="
                      (event) =>
                        addAttestation(event, attestation.id, boxItem[index])
                    "
                    :disabled="isDisabled(attestation.id)"
                  ></v-checkbox>
                  <span class="text1" v-text="attestation.text"> </span>
                </div>
                <div
                  class="footer-item d-flex align-center p-2"
                  :class="{ 'disabled-version': cannotDownloadAll }"
                  @click="downloadAttestion"
                >
                  <v-icon size="22" class="mr-2">mdi-download</v-icon>
                  <span class="text1" v-text="attestationDownloadText"> </span>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </td>
      </template>
      <span
        v-if="isNotAffiliated"
        class="help-text"
        style="width: 300px; min-height: 70px;"
        >Il n’est pas possible de télécharger des attestations de la saison
        non-affiliée</span
      >
      <span v-else class="help-text" style="width: 300px; min-height: 70px;"
        >Il n’est pas possible de télécharger des attestations de plus de deux
        ans</span
      >
    </v-tooltip>
    <td>
      <div class="d-flex align-center justify-content-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              color="#082b6e"
              class="mr-4"
              @click="navigateToFinancialFluxes(item)"
              :disabled="+item.ID_Saison < 2023"
              v-on="on"
              >mdi-bank
            </v-icon>
          </template>
          Accéder aux flux financiers pour cette saison
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              color="#082b6e"
              @click="navigateToUsers(item)"
              :disabled="+item.ID_Saison < 2023"
              v-on="on"
              >mdi-account-group
            </v-icon>
          </template>
          Accéder aux adhérents pour cette saison
        </v-tooltip>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  getLicenseeCount,
} from "@/plugins/structureService";

export default {
  computed: {
    ...mapGetters("saisons", ["currentSaisonId"]),
    isNavigatorFireFox() {
      const navigatorObject = window.navigator;
      return navigatorObject.userAgent.includes("Firefox");
    },

    item() {
      return this.context.item;
    },
    isNotAffiliated() {
      return this.context.item.LIST_EtapeAffiliation.id !== 3;
    },
    isMoreThanTwoYear() {
      console.log("this.context.item :>> ", this.context.item);
      if (this.context.item.ID_Saison > +this.currentSaisonId - 2) return false;
      return true;
    },
    isAnticipated() {
      if (this.context.item.ID_Saison > +this.currentSaisonId) return true;
      return false;
    },
    structurePresident() {
      if (
        !this.structureFonction?.length ||
        this.item.ID_Saison !== this.currentSaisonId
      )
        return "-";
      const president = this.structureFonction.find(
        (fonction) => fonction.FonctionUtilisateur?.id === 1
      );

      return president
        ? `${president?.UTI_Utilisateur?.CT_Prenom} ${president?.UTI_Utilisateur?.CT_Nom} `
        : "-";
    },
    cannotDownloadAll() {
      if (this.isNavigatorFireFox && this.attestationToDownload?.length < 1)
        return true;
      return false;
    },
    structureSecretary() {
      if (
        !this.structureFonction?.length ||
        this.item.ID_Saison !== this.currentSaisonId
      )
        return "-";
      const secretary = this.structureFonction.find(
        (fonction) => fonction.FonctionUtilisateur?.id === 3
      );
      return secretary
        ? `${secretary?.UTI_Utilisateur?.CT_Prenom} ${secretary?.UTI_Utilisateur?.CT_Nom}`
        : "-";
    },
    structureTresorary() {
      if (
        !this.structureFonction?.length ||
        this.item.ID_Saison !== this.currentSaisonId
      )
        return "-";

      const tresorary = this.structureFonction.find(
        (fonction) => fonction.FonctionUtilisateur?.id === 5
      );
      return tresorary
        ? `${tresorary?.UTI_Utilisateur?.CT_Prenom} ${tresorary?.UTI_Utilisateur?.CT_Nom} `
        : "-";
    },
  },
  props: {
    context: {
      type: Object,
    },
    structureFonction: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      test: null,
      boxItem: [false, false, false, false],
      attestationList: [
        { id: 1, text: "Attestation classique" },
        { id: 2, text: "Attestation avec le nombre de licenciés" },
        { id: 3, text: "Attestation avec le nombre de licenciés S-1" },
        { id: 4, text: "Attestation d’assurance RC" },
      ],
      attestationToDownload: [],
      attestationDownloadText: "Télécharger toutes les attestations",
      licenceNumber: {
        licence: 0,
        licence_gt_eighteen: 0,
        licence_lt_eighteen: 0,
        previouslicence: 0,
        licence_gt_eighteen_previous: 0,
        licence_lt_eighteen_previous: 0,
      },
    };
  },
  methods: {
    ...mapActions("structure", ["getSeasonLicence", "getLicenseeCount"]),

    isDisabled(id) {
      if (!this.isNavigatorFireFox || this.attestationToDownload.length < 1)
        return false;
      if (this.attestationToDownload.some((attestation) => attestation === id))
        return false;
      return true;
    },
    addAttestation(event, item, value) {
      if (!value) {
        const number = this.attestationToDownload.length - 1;
        this.downloadText(number);
      }
      if (event) {
        const attestation = this.attestationToDownload.push(item);
        const number = this.attestationToDownload.length;
        this.downloadText(number);
        return attestation;
      }
      return this.attestationToDownload.splice(
        this.attestationToDownload.indexOf(item),
        1
      );
    },
    async downloadAttestion() {
      if (this.cannotDownloadAll) return; // we disable download when cannot download
      // download all attestation if no selected
      console.log("context !!!!!!!!", this.context);
      this.context.licenceNumber = await this.licenceForSeason(this.context);

      console.log("context 2 !!!!!!!!", this.context);
      if (this.attestationToDownload.length < 1) {
        this.$emit("print-affiliation", {
          context: this.context,
        });

        this.$emit("print-affiliation2", {
          context: this.context,
        });
        this.$emit("print-affiliation3", {
          context: this.context,
        });
        this.$emit("print-rc", {
          context: this.context,
        });
        return (this.boxItem = [false, false, false, false]);
      }
      // attestation standarde
      if (
        this.attestationToDownload.some(
          (attestation) => attestation === this.attestationList[0].id
        )
      ) {
        this.$emit("print-affiliation", {
          context: this.context,
        });
      }
      // Attestation avec le nombres de licenciés
      if (
        this.attestationToDownload.some(
          (attestation) => attestation === this.attestationList[1].id
        )
      ) {
        this.$emit("print-affiliation2", {
          context: this.context,
        });
      }
      // Attestation avec le nombres de licenciés S-1
      if (
        this.attestationToDownload.some(
          (attestation) => attestation === this.attestationList[2].id
        )
      ) {
        console.log("context s - 1 !!!!!!!!", this.context);
        this.$emit("print-affiliation3", {
          context: this.context,
        });
      }
      //Attestation d’assurance RC
      if (
        this.attestationToDownload.some(
          (attestation) => attestation === this.attestationList[3].id
        )
      ) {
        this.$emit("print-rc", {
          context: this.context,
        });
      }
      this.boxItem = [false, false, false, false];
      return (this.attestationToDownload = []);
    },
    downloadText(number) {
      if (number === 1)
        this.attestationDownloadText = "Télécharger l'attestation";
      else if (number === 0)
        this.attestationDownloadText = "Télécharger toutes les attestations";
      else this.attestationDownloadText = "Télécharger les attestations";
    },
    async licenceForSeason(context) {
      // Licence count for the current season
      const count = await getLicenseeCount(context.item.ID_Saison, context.item.ID_Structure);

      this.licenceNumber.licence_gt_eighteen = count.adult;
      this.licenceNumber.licence_lt_eighteen = count.young;

      // Licence count for the previous season
      const countPreviousSeason = await getLicenseeCount(context.item.ID_Saison - 1, context.item.ID_Structure);

      this.licenceNumber.licence_gt_eighteen_previous = countPreviousSeason.adult;
      this.licenceNumber.licence_lt_eighteen_previous = countPreviousSeason.young;

      return this.licenceNumber;
    },
    getAffiliationStatusText(item) {
      if (
        (item.ID_Saison < this.currentSaisonId ||
          item.ID_Saison == this.currentSaisonId) &&
        item.LIST_EtapeAffiliation.id === 3
      ) {
        return "active";
      } else if (
        item.ID_Saison > this.currentSaisonId &&
        item.LIST_EtapeAffiliation.id === 3
      ) {
        return "active";
      } else {
        return "non-active";
      }
    },
    navigateToFinancialFluxes(item) {
      window.location = (`${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-finances/flux-financiers?season=${item.ID_Saison}&appMode=${localStorage.getItem("ffme_user_mode")}`)
    },
    navigateToUsers(item) {
      window.location = (`${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/utilisateurs?season=${item.ID_Saison}&appMode=${localStorage.getItem("ffme_user_mode")}`)
    },
  },
};
</script>
<style scoped>
.select-item {
  border: solid 1px #e7effd;
}
.footer-item {
  min-height: 52px;
  cursor: pointer;
}
.content-panel >>> .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.panel-extension {
  position: absolute;
  top: 2px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  border: solid 1px #e7effd !important;
}
.test {
  height: 95% !important;
}
.active {
  z-index: 45 !important;
  height: auto !important;
}
.text1 {
  font-family: Roboto;
  font-size: 14px;
  /* font-weight: normal; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
}
.text2 {
  font-size: 12px !important;
}
.disabled {
  color: #c5c5c5;
}
.help-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
}
.disabled-version {
  opacity: 0.3 !important;
  cursor: initial;
}
</style>
