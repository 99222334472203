<template>
  <div id="print-affiliation2" ref="print-affiliation2">
    <div class="container">
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <div class="content">
        <h4
          style="
            display: flex;
            justify-content: flex-end;
            padding-right: 200px;
            padding-top: 100px;
          "
        >
          A Paris, le {{ $moment().format("DD/MM/YYYY") }}
        </h4>
        <div>
          <h1 class="text-header">ATTESTATION</h1>
          <p class="attestation_text">
            Je soussigné Alain CARRIERE, président de la Fédération française de
            la montagne et de l'escalade, atteste que la structure "{{
              structureName
            }}" est affiliée à la fédération au titre de l'exercice
            {{ saison - 1 }}-{{ saison }} sous le numéro
            {{ structureCode }}.<br />
            {{ licence }} licences fédérales ont été délivrées à ce jour sur la
            saison {{ saison - 1 }}-{{ saison }} ({{
              licence_lt_eighteen
            }}
            licenciés de moins de 18 ans et {{ licence_gt_eighteen }} licenciés
            de plus de 18 ans), sous réserve que toutes les licences aient été
            acquittées. <br />
            En foi de quoi lui est délivrée la présente attestation.
          </p>
          <p class="attestation_president">
            Alain CARRIERE<br />
            Président
          </p>
          <div class="tag">
            <img
              src="~@/assets/attestattion/affiliation_signature.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapActions, mapGetters } from "vuex";
import { saveAs } from "file-saver";

export default {
  data: () => ({
    context: null,
    saison: 0,
    structureName: "",
    structureCode: "",
    licence: 0,
    licence_gt_eighteen: 0,
    licence_lt_eighteen: 0,
  }),

  methods: {
    printAffiliationTypeTwo(context) {
      this.context = context;
      this.saison = context.item.ID_Saison;
      this.structureName = context.NomStructure;
      this.structureCode = context.CodeFederal;
      this.licence_gt_eighteen = context.licenceNumber.licence_gt_eighteen;
      this.licence_lt_eighteen = context.licenceNumber.licence_lt_eighteen;
      this.licence = this.licence_gt_eighteen + this.licence_lt_eighteen;

      this.$nextTick(() => {
        this.$refs["print-affiliation2"].style.display = "block";
        let source = document.querySelector("#print-affiliation2");
        html2canvas(source, {
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg");
          const pdf = new jsPDF({
            orientation: "portrait",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
          var data = pdf.output();
          var buffer = new ArrayBuffer(data.length);
          var array = new Uint8Array(buffer);
          for (var i = 0; i < data.length; i++) {
            array[i] = data.charCodeAt(i);
          }

          var blob = new Blob([array], {
            type: "application/pdf",
            encoding: "raw",
          });
          pdf.save("Attestation Affiliation type 2 - " + this.saison);
          this.$refs["print-affiliation2"].style.display = "none";
        });
      });
    },
  },
};
</script>
<style lang="scss">
#print-affiliation2 {
  width: 1000px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  display: none;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    .habillage {
      width: 100%;
    }
  }
  .content {
    width: 100%;
    position: absolute;
    top: 10vh;
    height: 100%;
    font-family: "Roboto Condensed";
    color: black;
    .text-header {
      text-decoration: underline;
      font-family: "Roboto Condensed";
      font-size: 56px;
      display: flex;
      justify-content: center;
      padding-top: 200px;
      font-weight: bold;
      color: black;
    }
    .attestation_text {
      padding-top: 70px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      color: black;
      text-align: left;
      padding-left: 70px;
      padding-right: 70px;
    }
    .attestation_president {
      padding-top: 40px;
      font-size: 24px;
      color: black;
      text-align: left;
      padding-left: 70px;
      padding-right: 70px;
    }
    .tag {
      display: flex;
      justify-content: start;
      padding-left: 20%;
      height: 150px;
      width: 120%;
      z-index: 100;
    }
  }
}
</style>
