<template>
  <BaseModal
    headerText="ré-affiliation à la FFME"
    :is-open="isOpen"
    :is-loading="loading"
    :btnValidationText="'Procéder au paiement'"
    v-on="$listeners"
    :is-validation-btn-disabled="tarifAffiliation === 0 || seasonsList.length === 0"
    @validate="setPayment()"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center">
          <div
            class="d-flex align-center justify-space-between"
            style="width: 50%;"
          >
            <span class="text-normal"> Sélection de la saison </span>
            <InputSelect
              class="mt-5"
              :items="seasonsList"
              v-model="selectedSeason"
              style="width: 250px !important;"
            />
          </div>
          <span
            class="subtext ml-6"
            v-text="`Période de validité : ${validAbonnement}`"
          />
        </div>
        <div class="d-flex align-center my-2">
          <span
            v-text="'Description de la transaction'"
            class="text-subtitle"
          />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div
          class="d-flex align-center justify-space-between my-2"
          style="width: 50%;"
        >
          <span class="text-normal"> Objet de la transaction </span>
          <span class="subtext">
            Affiliation pour la saison {{ selectedSeason }}
          </span>
        </div>
        <div
          class="d-flex align-center my-1 justify-space-between mb-3"
          style="width: 50%;"
        >
          <span class="text-normal"> Montant par saison </span>
          <span class="text-blue"> {{ tarifAffiliation / 100 }} € </span>
        </div>
        <div
          class="d-flex align-center my-1 justify-space-between mb-3"
          style="width: 50%;"
        >
          <span class="text-normal"> Frais bancaires </span>
          <span class="text-blue"> {{ commission / 100 }} € </span>
        </div>
        <div class="alert-message">
          <span style="font-weight: bold;">Information : </span>
          <span> Le paiement s'effectue par carte bancaire.</span>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/utils/modals/BaseModal.vue";
import InputSelect from "@/components/utils/select.vue";
import { mapActions, mapGetters } from "vuex";
import { error_notification } from '@/plugins/notifications.js';
import { getTransactionReAffiliationMABody, insertTransaction } from "@/plugins/transactionService";
import {
  PRODUCT_AFFILIATION_MA,
  getProducts,
  getPrices
} from "@/plugins/productService";

export default {
  name: "ReaffiliationMA",
  components: { BaseModal, InputSelect },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("saisons", ["saisons", "currentSaison"]),
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("user", ["currentUserId"]),

    validAbonnement() {
      return `${this.$moment(
        new Date("2022-09-01").setFullYear(this.selectedSeason - 1)
      ).format("DD/MM/YYYY")} - ${this.$moment(
        new Date("2022-08-31").setFullYear(this.selectedSeason)
      ).format("DD/MM/YYYY")}`;
    },
    tarifAffiliation() {
      return this.tarifStructure;
    },
    commission() {
      return Math.ceil((this.tarifStructure * 0.0072) + 16);
    },
  },
  data() {
    return {
      selectedSeason: null,
      loading: false,
      tarifStructure: null,
      productId: null,
      seasonsList: [],
    };
  },
  created() {
    this.setData()
  },
  watch: {
    selectedSeason: {
      handler() {
        this.getMAPrice();
      },
      deep: false,
    },
  },
  methods: {
    ...mapActions("utilisateurs", ["createLemonwayPaiement"]),
    ...mapActions("structure", ["getStructureAffiliation"]),

    async setData() {
      await this.getValidSeasons();
      await this.getMAPrice();
    },
    async getValidSeasons() {
      const seasons = this.saisons.filter((season) => (this.currentStructure.STR_Structure.LIST_StructureType.SlugTypeStructure !== 'club_membre_associe' && season.ID_EtapeSaison === 3) || season.ID_EtapeSaison === 4);
      let validSeasons = [];

      for (const index in seasons) {
        const affiliation = await this.getStructureAffiliation({
          ID_Structure: this.currentStructureId,
          ID_Saison: seasons[index].id,
        });

        if (affiliation.ID_EtapeAffiliation !== 3) {
          validSeasons.push(seasons[index].id);
        }
      }

      this.seasonsList = validSeasons;
      this.selectedSeason = parseInt(validSeasons[0], 10);
    },
    async getMAPrice() {
      if (!this.selectedSeason) {
        return;
      }

      let products = await getProducts({
        'slug': PRODUCT_AFFILIATION_MA,
      });

      this.productId = products[0].id;

      let prices = await getPrices({
        structureId: this.currentStructureId,
        seasonId: this.selectedSeason,
        productId: PRODUCT_AFFILIATION_MA,
      });

      this.tarifStructure = prices.reduce((carry, price) => carry + price.value, 0);
    },
    async setPayment() {
      this.loading = true;

      try {
        const commissionLemonway = Math.ceil((this.tarifStructure * 0.0072) + 16);

        const transaction = await insertTransaction(
          getTransactionReAffiliationMABody(
            this.tarifStructure + commissionLemonway,
            this.productId,
            undefined,
            this.currentUserId,
            this.selectedSeason,
            this.currentStructureId,
          )
        );

        window.location.href = `${process.env.VUE_APP_FRONT_NEXT_URL}/paiement/${transaction.data.id}?appMode=${localStorage.getItem("ffme_user_mode")}`;
      } catch (error) {
        error_notification('Une erreur est survenue lors de la création de la transaction');
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-normal {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .subtext {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .text-blue {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #082b6e;
  }

  .alert-message {
    background: rgba(8, 43, 110, 0.1);
    border-left: 2px solid #082b6e;
    color: #082b6e;
    min-height: 50px;
    padding: 14px;
  }
}
</style>
