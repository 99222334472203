var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.mounted)?_c('div',[_vm._v("En cours de chargement...")]):_c('div',{staticClass:"affiliation__info_generale"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-alert',{attrs:{"border":"left","color":_vm.colorEtapeAffiliation}},[_c('p',{staticClass:"mb-0"},[_vm._v("Affiliation saison "+_vm._s(this.selectedSeason.id))]),_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.currentEtapeAffiliation.NomEtapeAffiliation))])])],1)],1),_c('h1',{staticClass:"font-weight-medium",staticStyle:{"font-size":"20px"}},[_vm._v("Étape 1 - Saisie des informations générales")]),_c('ValidateForm',{attrs:{"showFieldObl":false,"loading":_vm.loading},on:{"validated":_vm.submit}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"12px","letter-spacing":"1px"}},[_vm._v("Informations de correspondance")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Correspondant *")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('userAssign',{attrs:{"user":_vm.currentNomCorrespondant,"context":{
                hasCodeFederal: _vm.hasCodeFederal,
                ID_Structure: _vm.currentStructureId,
                ID_Saison: _vm.selectedSeason.id,
              },"disabled":_vm.disabled,"showAvatar":false},on:{"userSelected":_vm.userSelectedNomCorrespondant}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Adresse de correspondance *")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('assignCard',{attrs:{"context":{
                ID_Structure: _vm.currentStructureId,
                title: 'Adresse correspondant',
                key: 'ID_AdresseCorrespondance',
                type: 'correspondance',
              },"adresse":_vm.adresseCorrespondance(),"structureAdresses":_vm.adresses},on:{"onCardUpdate":_vm.setData}})],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-btn',{staticClass:"btn btn-primary",attrs:{"tile":""},on:{"click":function($event){return _vm.openAddModal(0)}}},[_vm._v("Ajouter une adresse")])],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"12px","letter-spacing":"1px"}},[_vm._v("Informations officielles")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('inputText',{attrs:{"label":"N° SIRET","rules":"siret","disabled":_vm.disabled},model:{value:(_vm.JUR_Siret),callback:function ($$v) {_vm.JUR_Siret=$$v},expression:"JUR_Siret"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('inputText',{attrs:{"label":"Agrément Jeunesse et sports","disabled":_vm.disabled},model:{value:(_vm.JUR_AgrementJeunesseSports),callback:function ($$v) {_vm.JUR_AgrementJeunesseSports=$$v},expression:"JUR_AgrementJeunesseSports"}})],1)],1),(!_vm.isStructureEA)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"12px","letter-spacing":"1px"}},[_vm._v("Membres du bureau")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Président")]),_c('userAssign',{attrs:{"user":_vm.userPresident,"context":{
            hasCodeFederal: _vm.hasCodeFederal,
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSeason.id,
          },"disabled":_vm.disabled,"showAvatar":false},on:{"userSelected":_vm.userFonctionPresidentSelected}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Secrétaire")]),_c('userAssign',{attrs:{"user":_vm.userSecretaire,"context":{
            hasCodeFederal: _vm.hasCodeFederal,
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSeason.id,
          },"disabled":_vm.disabled,"showAvatar":false},on:{"userSelected":_vm.userFonctionSecretaireSelected}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('p',[_vm._v("Trésorier")]),_c('userAssign',{attrs:{"user":_vm.userTresorier,"context":{
            hasCodeFederal: _vm.hasCodeFederal,
            ID_Structure: _vm.currentStructureId,
            ID_Saison: _vm.selectedSeason.id,
          },"disabled":_vm.disabled,"showAvatar":false},on:{"userSelected":_vm.userFonctionTresorierSelected}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"nowrap","gap":"15px"},attrs:{"cols":"12"}},[(_vm.canSubmit)?_c('v-btn',{staticClass:"btn btn-primary mt-2",attrs:{"disabled":!_vm.hasCodeFederal || _vm.disabled,"type":"submit","loading":_vm.loading}},[_vm._v(" Enregistrer les informations de cette étape ")]):_vm._e(),(_vm.canSubmit)?_c('v-btn',{staticClass:"btn btn-primary mt-2",attrs:{"disabled":!_vm.hasCodeFederal || _vm.disabled || !_vm.stepIsValid,"type":"button","loading":_vm.loading},on:{"click":function () { this$1.$router.push({ name: 'affiliations__activites_du_club' }) }}},[_vm._v(" Etape suivante ")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"btn btn-primary mt-2",attrs:{"type":"submit","disabled":true}},[_vm._v(" Enregistrer les informations de cette étape ")])],1)]}}])},[_c('span',[_vm._v("Vous n'avez pas les droits nécessaire pour accéder à ce module. Merci de contacter un administrateur.")])])],1)],1)],1),_c('AdresseModal',{ref:"adresseModal",attrs:{"isOpen":_vm.modalAddAddressIsOpen,"context":{ ID_Structure: _vm.currentStructureId },"tabInit":_vm.tabInit,"affiliation":true},on:{"onClose":function($event){_vm.modalAddAddressIsOpen = false},"onSelected":_vm.onItemSelected,"onUpdated":_vm.onUpdated,"onCardAddressUpdate":function($event){return _vm.adresseCorrespondance()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }