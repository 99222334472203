<template>
  <v-row>
    <v-col cols="12" v-if="withTitle">
      <h4>Historique de mes affiliations:</h4>
    </v-col>

    <v-col cols="12" class="px-0">
      <v-simple-table fixed-header height="750px">
        <template v-slot:default>
          <thead c>
            <tr>
              <th class="text-center text-title">Saison</th>
              <th class="text-center text-title">Président</th>
              <th class="text-center text-title">Secrétaire</th>
              <th class="text-center text-title">Trésorier</th>
              <th class="text-center text-title">Statut de l'affiliation</th>
              <th class="text-center text-title" style="min-width: 240px; max-width: 290px;">
                Attestations
              </th>
              <th class="text-center text-title">Actions</th>
            </tr>
          </thead>
          <tbody>
            <LineHistorique
              v-for="(item, index) in affiliation"
              :key="index"
              :context="{ ...context, item: item }"
              :structure-fonction="structureFonction"
              @print-affiliation="(data) => printAffiliation(data)"
              @print-affiliation2="(data) => printAffiliation2(data)"
              @print-affiliation3="(data) => printAffiliation3(data)"
              @print-rc="(data) => printRC(data)"
            />
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <PrintAffiliation ref="affiliation" :print="print"></PrintAffiliation>
    <PrintAffiliation2 ref="affiliation2" :print="print"></PrintAffiliation2>
    <PrintAffiliation3 ref="affiliation3" :print="print"></PrintAffiliation3>
    <PrintRC ref="rc" :print="print"></PrintRC>
  </v-row>
</template>

<script>
import PrintAffiliation from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/PrintAffiliation.vue";
import PrintAffiliation2 from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/PrintAffiliation2.vue";
import PrintAffiliation3 from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/PrintAffiliation3.vue";
import PrintRC from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/PrintRC.vue";
import LineHistorique from "@/modules/GestionDeLaStructure/HistoriqueAffiliations/Line__Attestation.vue";
export default {
  components: {
    LineHistorique,
    PrintAffiliation,
    PrintAffiliation2,
    PrintAffiliation3,
    PrintRC,
  },

  data: () => ({
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),
  computed: {
    affiliation() {
      return [...this.context.affiliations].sort(
        (a, b) => b.ID_Saison - a.ID_Saison
      );
    },
  },
  methods: {
    async printAffiliation({ context }) {
      await this.$refs.affiliation.printAffiliation(context);
    },
    async printAffiliation2({ context }) {
      // console.log("context printAffiliation2", context);
      await this.$refs.affiliation2.printAffiliationTypeTwo(context);
    },
    async printAffiliation3({ context }) {
      // console.log("context printAffiliation3", context);
      await this.$refs.affiliation3.printAffiliationTypeThree(context);
    },
    async printRC({ context }) {
      await this.$refs.rc.printRC(context);
    },
  },

  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    context: {
      type: Object,
    },
    structureFonction: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.text-title {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: white !important;
  background: #39558b !important;
}
</style>
