<template>
  <div class="w-100">
    <div>
      <v-alert dense color="orange lighten-5" class="my-5" icon="info">
        <div>
          <span class="font-italic">
            La loi oblige les places de marché et les plateformes de financement
            participatif à
          </span>
          <span class="font-weight-bold">
            mettre en place des procédures strictes de KYC
          </span>
          <span class="font-italic">
            (Know Your Customer - Vérification de l'identité des clients). Les
            lignes directrices de l’Autorité de Contrôle Prudentiel et de
            Résolution (ACPR) précisent les modalités de leur mise en œuvre. Les
            procédures, mises en place avant l’entrée en relation d’affaires,
            conditionnent l’ouverture d’un compte utilisateur. Les vérifications
            préalablement effectuées par les opérateurs de place de marché sont
            en effet destinées à
          </span>
          <span class="font-weight-bold">
            prévenir le risque de fraude, de blanchiment d’argent et de
            financement d’activités terroristes
          </span>
          <a href="https://www.lemonway.com/business-insight/lcb-ft/"
            >(LCB-FT).</a
          >
        </div>
      </v-alert>
    </div>
    <div v-if="haveUserAccount">
      <v-alert
        dense
        color="orange lighten-5"
        class="my-5 info_compte_kyc"
        icon="info"
      >
        Vous avez déjà un compte KYC
        <!-- <span class="font-weight-bold">{{ accountInfos.accountId }}</span> -->
      </v-alert>
    </div>
    <v-row justify="center">
      <v-col cols="12">
        <h6 class="ml-5 mt-5">Identité du représentant légal:</h6>
      </v-col>
      <v-col cols="12" xl="10" class="gestion-du-club__form">
        <ValidateForm @validated="submit" :showFieldObl="false">
          <div class="form-user-details__container mt-6">
            <InputText
              v-model="accountInfos.company.name"
              rules="min:0|max:100"
              :label="companyName"
              disabled
            ></InputText>
            <InputText
              v-model="accountInfos.email"
              rules="required|email"
              label="Adresse courriel"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>
            <InputText
              v-model="accountInfos.lastname"
              rules="required|min:2|max:250|regexname|dashCounter"
              label="Nom"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>
            <InputText
              v-model="accountInfos.firstname"
              rules="required|min:2|max:250|regexname"
              label="Prénom"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>

            <AddressAutocomplete
              :item="accountInfos.adresse.city"
              rules="required|min:6|max:50"
              label="Adresse"
              :minimum_characters="3"
              ref="adresseAuto"
              @onSelected="adresseSelected"
              :disabled="haveUserAccount || accountCreated"
            ></AddressAutocomplete>
            <InputText
              v-model="accountInfos.codePostal"
              :key="refreshCodePostal"
              :rules="limit"
              label="Code postal"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>
            <InputText
              v-model="accountInfos.ville"
              :key="refreshVille"
              rules="required|min:1|max:60"
              label="Ville"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>
            <!-- <InputText
              v-model="accountInfos.adresse.country"
              rules="required|min:3|max:3"
              label="Pays"
              :disabled="haveUserAccount"
            ></InputText> -->
            <countriesSelect
              v-model="currentPays"
              label="Pays"
              @onChange="NationaliteChange"
              :disabled="haveUserAccount || accountCreated"
            ></countriesSelect>
            <datePicker
              v-model="birthday"
              rules="date|required"
              label="Date de naissance"
              withIcon="false"
              :disabled="haveUserAccount || accountCreated"
            />
            <!-- <InputText
              v-model="accountInfos.nationality"
              rules="required|min:3|max:3"
              label="Nationalité"
              :disabled="haveUserAccount"
            ></InputText> -->
            <countriesSelect
              v-model="currentNationalite"
              label="Nationalité"
              :disabled="haveUserAccount || accountCreated"
            ></countriesSelect>
            <InputText
              v-model="accountInfos.phoneNumber"
              rules="required|min:0|max:50"
              label="Telephone"
              :disabled="haveUserAccount || accountCreated"
            ></InputText>
          </div>

          <div class="form-user-details__action">
            <v-btn
              type="submit"
              class="btn btn-primary mt-5"
              :disabled="haveUserAccount || accountCreated"
              >Créer le compte</v-btn
            >
          </div>
        </ValidateForm>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InputText from "@/components/utils/input.vue";
import ValidateForm from "@/components/utils/form.vue";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import { sleep } from "@/plugins/utils";
import countriesSelect from "@/components/utils/select__countries.vue";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";

export default {
  components: {
    InputText,
    AddressAutocomplete,
    // selectInput,
    ValidateForm,
    countriesSelect,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },
  data: () => ({
    accountInfos: {
      company: {
        name: "",
        description: "",
      },
      accountId: "",
      email: "",
      firstname: "",
      lastname: "",
      adresse: {
        city: "",
        country: "",
      },
      codePostal: "",
      ville: "",
      birth: {
        date: "",
      },
      nationality: "",
      phoneNumber: "",
      payerOrBeneficiary: "",
    },
    typeAccount: [
      { name: "Payeur", value: 1 },
      { name: "Benéficiaire", value: 2 },
    ],
    birthday: "",
    haveAccount: null,
    restrictCountry: "FR",
    refreshCodePostal: 99,
    refreshVille: 0,
    limit: "",
    currentPays: null,
    currentNationalite: {},
    listPays: [],
    currentAddress: "",
    accountCreated: false,
  }),

  async created() {
    this.accountInfos.adresse.city = this.currentUser.ADR_Adresse[0].Adresse1;
    this.birthday = this.$moment(this.currentUser.DN_DateNaissance).format(
      "YYYY-MM-DD"
    );
    await this.setData();
    console.log("currentStructureId", this.currentStructureId);

    console.log("currentStructure", this.currentStructure);
    console.log("currentUser", this.currentUser);
    // console.log("getUserPaymentAccount", await this.getUserPaymentAccount());
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("user", ["currentUser"]),

    companyName() {
      if (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == "club_etablissement_affilie"
      )
        return "Nom de l’établissement";
      return "Nom de la structure";
    },
    haveUserAccount() {
      if (this.haveAccount) return true;
      return false;
    },
    haveUserInfos() {
      if (this.currentUser) return true;
      return false;
    },
  },

  methods: {
    ...mapActions("basics", ["getListPays"]),
    ...mapActions("utilisateurs", [
      "getUserPaymentAccount",
      "updateUserPaymentAccount",
    ]),

    async setData() {
      this.listPays = await this.getListPays();

      const getAllPaymentAccount = await this.getUserPaymentAccount();

      if (getAllPaymentAccount) {
        this.haveAccount = getAllPaymentAccount.find(
          (account) => account.ID_Structure == this.currentStructureId
        );
        console.log("this.haveAccount", this.haveAccount);
      }
      this.accountInfos.company.name = this.haveAccount?.Description
        ? this.haveAccount.Description
        : this.currentStructure.STR_Structure.NomStructure;
      this.accountInfos.company.description = this.currentStructure.STR_Structure.NomStructure;
      this.accountInfos.payerOrBeneficiary = 2;
      this.accountInfos.accountId = this.currentStructureId.toString();
      // this.accountInfos.accountId = "testCode12";

      this.accountInfos.firstname = this.currentUser.CT_Prenom;
      this.accountInfos.lastname = this.currentUser.CT_Nom;
      this.accountInfos.email = this.currentUser.CT_Email;
      // console.log("accountInfos.adresse.city", this.accountInfos.adresse.city);
      this.accountInfos.codePostal = this.currentUser.ADR_Adresse[0].CodePostal;
      this.accountInfos.ville = this.currentUser.ADR_Adresse[0].Ville;

      this.accountInfos.phoneNumber = this.currentUser.CT_TelephoneMobile;

      if (
        this.currentUser &&
        this.currentUser.ADR_Pays &&
        this.$refs.adresseAuto
      )
        this.$refs.adresseAuto.setRestriction(
          this.currentUser.ADR_Pays.CodePays
        );

      if (this.currentUser.ADR_Pays) {
        this.currentPays = this.currentUser.ADR_Pays;
      }
      if (this.currentUser.ID_PaysNationalite) {
        this.currentNationalite = this.listPays.find(
          (p) => p.id == this.currentUser.ID_PaysNationalite
        );
      } else {
        this.currentNationalite = {};
      }
    },

    onPaysChanged(item) {
      this.currentUser.ID_Pays = item.id;
      this.currentUser.ADR_Pays = item;
      // this.restrictCountry = item.CodePays;
      // this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.currentPays = item;
    },

    NationaliteChange(item) {
      this.currentUser.ID_PaysNationalite = item.id;
    },

    async adresseSelected(item) {
      if (typeof item === "string")
        return (this.accountInfos.adresse.city = item);

      await sleep(10);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1?.toUpperCase();
      // this.accountInfos.accountId = this.currentUser.id;
      this.accountInfos.adresse.city = adresse1?.toUpperCase();
      this.accountInfos.ville = item[2].long_name?.toUpperCase();
      this.accountInfos.codePostal = item[6]
        ? item[6].long_name?.toUpperCase()
        : "";
      this.refreshCodePostal++;
      this.refreshVille++;
    },

    async createUserAccount() {
      try {
        let payload = {
          NomCompte: this.accountInfos.accountId,
          Description: this.accountInfos.company.description,
          ID_Utilisateur: this.currentUser.id,
          ID_Structure: this.currentStructure.STR_Structure.id,
        };
        let response = await this.updateUserPaymentAccount({
          userAccount: payload,
        });
      } catch (e) {
        console.log("error===>", e);
      }
    },

    async submit() {
      let pays = [...this.getList.ADR_Pays];

      const country = pays.filter((c) => c.id === this.currentUser.ADR_Pays.id);
      const nationality = pays.filter(
        (c) => c.id === this.currentUser.ID_PaysNationalite
      );
      // console.log("country", country);
      // console.log("nationality", nationality);

      this.accountInfos.adresse.country = country[0].CodePaysISO;
      this.accountInfos.nationality = nationality[0].CodePaysISO;

      this.accountInfos.birth.date = this.$moment(this.birthday).format(
        "YYYY/MM/DD"
      );

      // console.log("this.accountInfos", this.accountInfos);

      await this.createUserAccount();

      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/dataAccountKyc`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(this.accountInfos),
          }
        );

        const dataAccountKyc = await response;
        // console.log("dataAccountKyc ====>", dataAccountKyc);
        if (dataAccountKyc.ok === true) {
          await this.callCreateAccount();
        }
      } catch (error) {
        console.log("erro r:", error);
      }
    },

    async callCreateAccount() {
      try {
        const fetch = require("node-fetch");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
        };

        const response = await fetch(
          `${process.env.VUE_APP_LEMONWAY_API}/payment/createAccountKyc`,
          {
            method: "get",
            headers: headers,
          }
        );
        const json = await response.json();
        // console.log("json response payment====>", json);
        if (json.legalAccount) {
          success_notification("Compte KYC créé");
          this.accountCreated = true;
        } else {
          if (json.error.code === 204)
            error_notification(
              `L'adresse mail saisie est déjà utilisée sur un autre compte KYC. Merci de saisir une autre adresse mail`
            );
          else error_notification(`${json.error.message}`);
        }
      } catch (error) {
        console.log("erro r:", error);
        error_notification(`${error.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}

.info_compte_kyc {
  color: #3a1e00 !important;
  font-weight: bold;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}

p {
  margin: 0;
  color: #000000;
  font-size: 10pt;
}
</style>
